import toast from "react-hot-toast";

export const SuccessNotificationToast = (message) => {
  return toast.success(message, {
    style: {
      border: "1px solid rgb(34 197 94)",
      padding: "16px",
      color: "rgb(34 197 94)",
    },
    iconTheme: {
      primary: "rgb(34 197 94)",
      secondary: "#FFFAEE",
    },
  });
};

export const ErrorNotificationToast = (message) => {
  return toast.error(message, {
    style: {
      border: "1px solid #FF0000",
      padding: "16px",
      color: "#FF0000",
    },
    iconTheme: {
      primary: "#FF0000",
      secondary: "#FFFAEE",
    },
  });
};
