const StatusChip = ({ status }) => {
  return (
    <span
      className={`rounded-full ${
        status === null
          ? "bg-orange-500"
          : !status
          ? "bg-rose-500"
          : "bg-green-500"
      } px-3 py-2 text-white shadow-xl cursor-pointer`}
    >
      {status === null ? "Pending..." : !status ? "Failed" : "Verified"}
    </span>
  );
};

export default StatusChip;
