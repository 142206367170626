import axios from "axios";
import appConfig from "./config";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = appConfig.baseUrl;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    const authToken = localStorage.getItem("accessToken");
    if (authToken) {
      axiosConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }
    if (axiosConfig.rawRequest) {
      // header for file_upload, video/audio will handle here i.e raw requests
    } else {
      axiosConfig.headers["Content-Type"] = "application/json";
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
