import moment from "moment";

const VerificationLogs = ({ logs }) => (
  <table className="w-full border-collapse">
    <thead>
      <tr>
        <th className="py-2 px-4 border border-black font-bold">Date</th>
        <th className="py-2 px-4 border border-black font-bold">Time</th>
        <th className="py-2 px-4 border border-black font-bold">Type</th>
        <th className="py-2 px-4 border border-black font-bold">Status</th>
      </tr>
    </thead>
    <tbody>
      {logs.map((child) => (
        <tr>
          <td className="py-2 px-4 border border-black">
            {moment(child.created_at).format("MM/DD/YYYY")}
          </td>
          <td className="py-2 px-4 border border-black">
            {moment(child.created_at).format("h:mm a")}
          </td>
          <td className="py-2 px-4 border border-black">
            {child.type === "SELFIE_VERIFICATION"
              ? "Selfie Verification"
              : "Voice Verification"}
          </td>
          <td
            className={`${
              child.status === 1 ? `text-green-600` : `text-rose-500`
            } py-2 px-4 border border-black`}
          >
            {child.status === 1 ? "Verified" : "Failed"}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default VerificationLogs;
