import React, { useEffect } from "react";
import { app, pages } from "@microsoft/teams-js";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */

const TabConfig = () => {
  useEffect(() => {
    (async () => {
      app.initialize().then(() => {
        /**
         * When the user clicks "Save", save the url for your configured tab.
         * This allows for the addition of query string parameters based on
         * the settings selected by the user.
         */
        pages.config.registerOnSaveHandler((saveEvent) => {
          const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
          pages.config
            .setConfig({
              suggestedDisplayName: "PatientVerifi",
              entityId: "Test",
              contentUrl: baseUrl + "/index.html#/tab",
              websiteUrl: baseUrl + "/index.html#/tab",
            })
            .then(() => {
              saveEvent.notifySuccess();
            });
        });

        /**
         * After verifying that the settings for your tab are correctly
         * filled in by the user you need to set the state of the dialog
         * to be valid.  This will enable the save button in the configuration
         * dialog.
         */
        pages.config.setValidityState(true);

        // Hide the loading indicator.
        app.notifySuccess();
      });
    })();
  }, []);

  return (
    <div>
      <div class="p-8 bg-white rounded-lg shadow-md">
        <h1 class="text-2xl font-bold mb-4">
          Welcome to PatientVerifi Integration with Microsoft Teams!
        </h1>
        <p class="text-gray-700 mb-4">
          You’re about to empower your telehealth services with seamless and
          secure patient verification. By adding PatientVerifi as a tab in
          Microsoft Teams, you’re taking a significant step towards enhancing
          the safety, efficiency, and compliance of your telehealth offerings.
        </p>

        <h2 class="text-xl font-semibold mb-2">Getting Started:</h2>
        <ul class="list-disc list-inside mb-4">
          <li>
            Add PatientVerifi: Simply click on the “Add a tab” button in your
            desired channel or chat and select PatientVerifi from the available
            options.
          </li>
          <li>
            Begin Verification: Once set up, you’re ready to start verifying
            patient identities securely and efficiently during your telehealth
            sessions.
          </li>
        </ul>

        <p class="text-gray-700">
          Thank you for choosing PatientVerifi to secure your digital healthcare
          journey. Together, we’re transforming telehealth into a safer, more
          accessible service for everyone involved.
        </p>
      </div>
    </div>
  );
};

export default TabConfig;
