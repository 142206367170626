import React, { useCallback, useEffect, useState } from "react";
import { app, teamsCore } from "@microsoft/teams-js";
import { IoIosArrowUp } from "react-icons/io";
import VerificationLogs from "./VerificationLogs";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import StatusChip from "./StatusChip";
import { Toaster } from "react-hot-toast";
import {
  ErrorNotificationToast,
  SuccessNotificationToast,
} from "./NotificationToasts";
import appConfig from "../utils/config";

// import MediaQuery from "react-responsive";

const Tab = () => {
  const [meetingId, setMeetingId] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(null);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selfieStatusBar, setSelfieStatusBar] = useState(null);
  const [voiceStatusBar, setVoiceStatusBar] = useState(null);
  const [verificationLogs, setVericationLogs] = useState([]);
  const [verifyingStatus, setVerifyingStatus] = useState("");
  const [meetingData, setMeetingData] = useState([]);
  const [selfieStatus, setSelfieStatus] = useState({
    userId: null,
    verifiationDateTime: "",
    verificationStatus: null,
  });
  const [voiceStatus, setVoiceStatus] = useState({
    userId: null,
    verifiationDateTime: "",
    verificationStatus: null,
  });

  const navigate = useNavigate();

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const changeMeetingStatus = useCallback(async () => {
    try {
      const { data } = await axios.post("/teams/completeAppointment", {
        meeting_id: meetingId,
      });
      if (data.status) {
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  });

  const getPatientVerification = useCallback(
    async (signal) => {
      try {
        const { data } = await axios.post(
          `/userVerificationLog/${userId}`,
          { meeting_id: meetingId },
          { ...(signal && { signal }) }
        );

        if (data?.data?.length) {
          if (data.data.length === 2) {
            await changeMeetingStatus();
          }
          setVericationLogs(data?.data);
        }
      } catch (error) {
        console.log("incode onboarding =>", error);
      }
    },
    [userId, meetingId]
  );

  const getUserData = useCallback(async () => {
    try {
      const { data } = await axios.get(`get-user-details/${meetingId}`);
      if (data) {
        setMeetingData(data);
        setUserId(data.patient_id);
      }
    } catch (error) {
      console.log("incode onboarding =>", error);
    }
  }, [meetingId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (userId) {
      getPatientVerification(signal);
    }
    return () => {
      controller.abort();
    };
  }, [getPatientVerification, userId]);

  useEffect(() => {
    if (verificationLogs.length) {
      const voiceLogs = [];
      const selfieLogs = [];
      verificationLogs.forEach((element) => {
        if (element.type === "AUDIO_VERIFICATION") {
          voiceLogs.push(element);
        }
        if (element.type === "SELFIE_VERIFICATION") {
          selfieLogs.push(element);
        }
      });
      if (voiceLogs.length) {
        setVoiceStatusBar(voiceLogs[0].status);
      }
      if (selfieLogs.length) {
        setSelfieStatusBar(selfieLogs[0].status);
      }
    }
  }, [verificationLogs]);

  const socketConnection = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const stagingSocketUrl = appConfig.wsURL;
    const ws = new WebSocket(stagingSocketUrl);

    ws.onopen = () => {
      console.log("Connected to the server");
    };
    ws.onerror = (e) => {
      console.log("OnError =>", e);
      ws.close();
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log("itself socket =>", data);

      if (data.data?.user_id === userId) {
        if (data?.type === "selfie_verification") {
          setSelfieStatus({
            userId: data?.data?.user_id,
            verifiationDateTime: data?.data?.verifiation_date_time,
            verificationStatus: data?.data?.verification_status,
          });
        } else if (data?.type === "audio_verification") {
          setVoiceStatus({
            userId: data?.data?.user_id,
            verifiationDateTime: data?.data?.verifiation_date_time,
            verificationStatus: data?.data?.verification_status,
          });
        } else if (
          data?.type === "end_meeting" &&
          data.data?.meeting_id === meetingId &&
          data.data?.host_id === userName
        ) {
          navigate("/meetingEnded", { replace: true });
        } else if (data?.type === "meeting_status") {
          if (data?.data?.status === "left") {
            setVerifyingStatus("left");
          } else if (data?.data?.status === "join") {
            setVerifyingStatus("join");
            setTimeout(() => {
              setVerifyingStatus("");
            }, 7000);
          } else {
            setVerifyingStatus("failed");
            setTimeout(() => {
              setVerifyingStatus("");
            }, 7000);
          }
          console.log("DATAAAA", data);
        }
      }
      getPatientVerification(signal);
    };
    ws.onclose = (e) => {
      console.log("Disconnected. Check internet or server.", e);
      socketConnection();
    };
  }, [getPatientVerification, userId, meetingId, userName, navigate]);

  useEffect(() => {
    if (userName && userId) {
      socketConnection();
    }
  }, [userName, socketConnection, userId]);

  useEffect(() => {
    if (meetingId) {
      getUserData();
    }
  }, [getUserData, meetingId]);

  useEffect(() => {
    app.initialize().then(() => {
      // Notifies that the app initialization is successfully and is ready for user interaction.
      app.notifySuccess();

      // Get the user context from Teams and set it in the state
      app.getContext().then(async (context) => {
        console.log(context);
        if (context) {
          if (context?.meeting?.id) {
            let decodedMeetingId = Buffer.from(
              context?.meeting?.id,
              "base64"
            ).toString("ascii");
            let chatId = decodedMeetingId.replace(/^0#|#0$/g, "");
            // let chatidURL = context?.meeting?.id
            // let chatidURL = encodeURIComponent(chatId)
            setMeetingId(chatId);
          }
          if (context?.user?.userPrincipalName)
            setUserName(context.user.userPrincipalName);
        }

        // Enable app caching.
        // App Caching was configured in this sample to reduce the reload time of your app in a meeting.
        // To learn about limitations and available scopes, please check https://learn.microsoft.com/en-us/microsoftteams/platform/apps-in-teams-meetings/app-caching-for-your-tab-app.
        if (context.page.frameContext === "sidePanel") {
          teamsCore.registerOnLoadHandler((context) => {
            // Use context.contentUrl to route to the correct page.
            app.notifySuccess();
          });

          teamsCore.registerBeforeUnloadHandler((readyToUnload) => {
            // Dispose resources here if necessary.
            // Notify readiness by invoking readyToUnload.
            readyToUnload();
            return true;
          });
        }
      });
    });
  }, []);

  useEffect(() => {}, []);

  const handleSendNotification = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`/pushnotification/${userId}`, {
        meetingId: meetingId,
        password: meetingData.meeting_password,
      });
      if (data.status) {
        SuccessNotificationToast(data.message);
      } else {
        ErrorNotificationToast(data.message);
      }
    } catch (error) {
      console.error(error);
      ErrorNotificationToast(error.message);
    } finally {
      setLoading(false);
    }
  }, [userId, meetingId, meetingData]);

  return (
    <PerfectScrollbar>
      <Toaster position="bottom-left" reverseOrder={false} />
      {meetingId && meetingData ? (
        <>
          <header className="p-2 rounded-2xl bg-[#F1F3F3]">
            <div className="flex flex-col gap-y-8 lg:flex-row items-start md:justify-between flex-wrap">
              {/* Patient Details */}

              <div className="bg-white shadow-xl flex flex-col gap-2 rounded-2xl p-4 min-w-[245px]">
                <h2 className="font-semibold text-base md:text-xl text-center text-[#666984] mb-2">
                  Patient Details
                </h2>

                <div className="flex text-sm md:text-base text-[#666984]">
                  <p className="w-28 font-semibold">First Name:</p>
                  <p>{meetingData.patient_details?.first_name}</p>
                </div>
                <div className="flex text-sm md:text-base text-[#666984]">
                  <p className="w-28 font-semibold">Last Name:</p>
                  <p>{meetingData.patient_details?.last_name}</p>
                </div>

                <div className="flex text-sm md:text-base text-[#666984]">
                  <p className="w-28 font-semibold">Date of Birth:</p>
                  <p>
                    {moment(meetingData.patient_details?.date_of_birth).format(
                      "MM-DD-YYYY"
                    )}
                  </p>
                </div>

                <div className="flex text-sm md:text-base text-[#666984]">
                  <p className="w-28 font-semibold">Gender:</p>
                  <p>
                    {meetingData.date_of_birth?.gender === 0
                      ? "Female"
                      : "Male"}
                  </p>
                </div>
              </div>

              {/* Patient Identity Verification */}

              <div className="bg-white shadow-xl flex flex-col gap-4 rounded-2xl p-4 min-w-[245px]">
                <h2 className="font-semibold text-base md:text-xl text-center text-[#666984] mb-2">
                  Patient Identity Verification
                </h2>

                <div className="flex items-center gap-4 text-sm md:text-base">
                  <span className="font-medium text-[#666984]">
                    Voice Verification:
                  </span>
                  <StatusChip status={voiceStatusBar} />
                </div>

                <div className="flex items-center justify-start gap-4 text-sm md:text-base">
                  <span className="font-medium text-[#666984]">
                    Selfie Verification:
                  </span>
                  <StatusChip status={selfieStatusBar} />
                </div>
              </div>

              {/* Actions */}
              <div className="bg-white shadow-xl flex flex-col gap-6 rounded-2xl p-4 min-w-[245px]">
                <h2 className="font-semibold text-base md:text-xl text-center text-[#666984]">
                  Actions
                </h2>

                <div className="flex flex-col gap-2">
                  <div>
                    <button
                      disabled
                      class="bg-slate-400 hover:bg-slate-500 py-2 px-6 rounded-full text-white text-center shadow-xl cursor-pointer"
                    >
                      Fetch Patient Files From Epic
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={handleSendNotification}
                      disabled={loading}
                      className="bg-blue-500  hover:bg-blue-600 w-full active:bg-blue-700 py-2 px-3 rounded-full text-white text-center shadow-xl cursor-pointer"
                    >
                      Request a Selfie Verification
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <footer className="my-4 p-2">
            <div
              className="font-bold p-4 text-center text-lg cursor-pointer bg-white shadow-md border rounded-2xl "
              onClick={toggleContent}
            >
              <span className="text-[#666984] text-sm md:text-lg">
                Patient Verification Logs{" "}
              </span>
              <div
                className={`float-right mt-1  ${
                  isContentVisible ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowUp />
              </div>

              <div
                className={`px-4 mt-5 overflow-x-auto ${
                  isContentVisible ? "" : "hidden"
                }`}
              >
                <VerificationLogs logs={verificationLogs} />
              </div>
            </div>
          </footer>
        </>
      ) : (
        <p className="text-xl text-red-500">
          You must start a meeting to access this app.
        </p>
      )}
    </PerfectScrollbar>
  );
};

export default Tab;
