import React from "react";
/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */

const Privacy = () => {
  return (
    <>
      <h1>Privacy Statement</h1>
    </>
  );
};

export default Privacy;
